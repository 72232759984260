var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blogs-page"},[_c('PageLoader'),_c('div',{staticClass:"row phones m-0"},[_c('div',{staticClass:"img1"},[_c('div',{staticClass:"img1all text-center text-white"},[_c('div',{staticClass:"breadcrumb-wrap"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ul',{staticClass:"breadcrumb justify-content-center"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(this.$route.params.id)+" ")]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(this.$route.params.brand)+" ")])])])]),_c('h1',[_vm._v(_vm._s(this.$route.params.brand)+" Deals")]),_c('p',{staticClass:"pimg"},[_vm._v("Compare the best "+_vm._s(this.$route.params.brand)+" Deals")])])])]),_c('div',{staticClass:"blog-area-wrapper pt-40 pb-70"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 order-first order-lg-last single_product page2 home_page home"},[_c('div',{staticClass:"featured-categories-area pb-40 new_card pt-5"},[_c('ul',{staticClass:"row text-center mb-5 justify-content-center"},[_vm._l((_vm.productCatBrand),function(singleBrand1,i){return [_c('li',{key:singleBrand1.slug + i,staticClass:"col-md-3 Most_popular_phones_img mt-4 "},[_c('router-link',{attrs:{"to":'/categories/' +
                          _vm.returnCat +
                          '/' +
                          _vm.returnBrand +
                          '/' +
                          singleBrand1.slug +
                          '?product=' +
                          singleBrand1.id,"tag":"a"}},[_c('img',{staticStyle:{"width":"8rem","height":"auto"},attrs:{"src":_vm.publicPath + singleBrand1.img}}),_c('br'),_c('br'),_c('span',{staticClass:"Most_popular_phones_span"},[_vm._v(_vm._s(singleBrand1.title))])])],1)]})],2)])])])])]),(_vm.singleBrand.content.length > 0)?[_c('section',{attrs:{"id":"contents"}},[_c('div',{staticClass:"description description_sec pt-90 mt-5 pt-5 description_section"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"row m-0 pb-4 pl-4"},[_vm._v(" Description ")]),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-lg-8 desc_body"},_vm._l((this.singleBrand.content),function(content,i){return _c('div',{key:i,staticClass:"mt-20 pb-20",attrs:{"id":'tab-' + content.title}},[_c('h3',{staticClass:"text-left mb-40 pt-4"},[_vm._v(_vm._s(content.title))]),_c('div',{domProps:{"innerHTML":_vm._s(content.desc)}}),_c('div',{staticClass:"mt-20",staticStyle:{"border":"solid 2px gray"}})])}),0),_c('div',{staticClass:"col-lg-4 Networks_Brands_img"},[_c('div',{staticClass:"row bg_sky m-auto pb-5"},[_c('div',{staticClass:"network col-lg-12 box_shadow pt-4"},[_c('h4',{staticClass:"pb-1 pt-3 pb-2"},[_vm._v("Our Top Providers")]),_c('div',{staticClass:"row justify-content-center"},[_vm._l((_vm.networks),function(network,i){return [_c('router-link',{key:network.title + i,staticClass:"network-sub-product brand_phone brand_product",attrs:{"to":'/networks/' + network.slug + '?network=' + network.id,"tag":"a"}},[_c('img',{attrs:{"src":_vm.publicPath + network.img,"alt":""},on:{"click":function($event){return _vm.filnetworkAdd(network.id)}}})])]})],2)])])])])])])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }