<template>
  <!-- blogs start -->
  <div class="blogs-page">
    <PageLoader />
    <div class="row phones m-0">
      <!-- :style="{ backgroundImage: `url('${publicPath + singleCategorys.banner}')` }" -->
      <div class="img1"> 
          <div class="img1all text-center text-white">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul class="breadcrumb justify-content-center">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ this.$route.params.id }}
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ this.$route.params.brand }}
                  </li>
                </ul>
              </nav>
            </div>
              <h1>{{ this.$route.params.brand }} Deals</h1>
              <p class="pimg">Compare the best {{ this.$route.params.brand }} Deals</p>
          </div>
      </div>
    </div>

    <!-- blog wrapper start -->
    <div class="blog-area-wrapper pt-40 pb-70">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-12 order-first order-lg-last single_product page2 home_page home"
          >
            <div class="featured-categories-area pb-40 new_card pt-5">
              <ul class="row text-center mb-5 justify-content-center">
                <template v-for="(singleBrand1, i) in productCatBrand" >
                  <li :key="singleBrand1.slug + i" class="col-md-3 Most_popular_phones_img mt-4 ">
                      <router-link
                        :to="
                          '/categories/' +
                            returnCat +
                            '/' +
                            returnBrand +
                            '/' +
                            singleBrand1.slug +
                            '?product=' +
                            singleBrand1.id
                        "
                        tag="a"
                      >
                        <img :src=" publicPath + singleBrand1.img" style="width: 8rem;height: auto;">
                        <br>
                        <br>
                        <span class="Most_popular_phones_span">{{ singleBrand1.title }}</span>
                      </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog wrapper end -->
    <template v-if="singleBrand.content.length > 0">
      <section id="contents">
        <div class="description description_sec pt-90 mt-5 pt-5 description_section">
          <div class="container">
            <h2 class="row m-0 pb-4 pl-4">
              Description
            </h2>
            <div class="row m-0">
              <div class="col-lg-8 desc_body">
                <div
                  v-for="(content, i) in this.singleBrand.content"
                  :key="i"
                  :id="'tab-' + content.title"
                  class="mt-20 pb-20"
                >
                  <h3 class="text-left mb-40 pt-4">{{ content.title }}</h3>
                  <div v-html="content.desc"></div>
                  <div class="mt-20" style="border:solid 2px gray"></div>
                </div>
              </div>
              <div class="col-lg-4 Networks_Brands_img">
                <div class="row bg_sky m-auto pb-5">
                  <div class="network col-lg-12 box_shadow pt-4">
                      <h4 class="pb-1 pt-3 pb-2">Our Top Providers</h4>
                    <div class="row justify-content-center">
                      <template v-for="(network, i) in networks" >
                        <router-link :key="network.title + i" :to=" '/networks/' + network.slug + '?network=' + network.id " tag="a" 
                          class="network-sub-product brand_phone brand_product">
                          <img @click="filnetworkAdd(network.id)" :src="publicPath + network.img " alt>
                        </router-link>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
  <!-- blogs end -->
</template>
<script>
import PageLoader from "@/components/units/PageLoader.vue";
export default {
  name: "newsCopm",
  components: {
    PageLoader
  },
  data() {
    return {
      singleBrand: [],
      productCatBrand: [],
      popularCat: [],
      networks: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getData();
    this.getDesc();
    this.getPopCat();
    this.getNetwork();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    returnBrand() {
      return this.$route.params.brand;
    },
    returnCat() {
      return this.$route.params.id;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
      this.getDesc();
      this.getPopCat();
      this.getNetwork();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http
        .get(
          "brandCategory?cat_slug=" +
            this.$route.params.id +
            "&brand_slug=" +
            this.$route.params.brand
        )
        .then(response => {
          this.productCatBrand = response.data.data;
          response => (this.productCatBrand = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getDesc() {
      this.loading = true;
      this.$http
        .get("brands/" + this.$route.params.brand)
        .then(response => {
          this.singleBrand = response.data.data;
          response => (this.singleBrand = response.data.data);
          console.log(this.$route.params.brand);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getPopCat() {
      this.loading = true;
      this.$urlmain
        .get("category-popular.json")
        .then(response => {
          this.popularCat = response.data.data;
          response => (this.popularCat = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getNetwork() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
